<template>
	<div>
		<img src="../../../assets/imges/index/8.png" class="headerimg" />
		<div class="Position">
			<div class="header">
				<span class="title1">加入我们</span><span class="arrows">></span>
				<span class="title2" @click="towelfare">薪酬福利</span>
				<span class="title2 title2hover">员工招聘</span>
				<span class="title2" @click="toprogress">人才发展</span>
			</div>
			<div class="conter">
				<div class="timeline">
					<div class="operation">
						<span @click="getCheckedNodes">筛选</span>
						<span @click="resetChecked" class="close">清除</span>
					</div>
					<div class="title">
						职位类别
					</div>
					<el-tree :data="data" show-checkbox node-key="id" default-expand-all :expand-on-click-node="false" ref="tree">
						<span class="custom-tree-node" slot-scope="{ node, data }">
							<span>{{ data.name }}</span>
						</span>
					</el-tree>
				</div>

				<div class="list">
					<div class="searchinput">
						<el-input v-model="form.value" placeholder="搜索您想要的职位">
							<div slot="append" @click="getinfolist">
								<i class="el-icon-search" ></i>
							</div>
						</el-input>
					</div>
					<div class="direction">
						<span>招聘方向</span>
						<span v-for="(item,index) in HiringDirectionList" :class="['directionbutton',{directionbuttonclick:form.type==item.id}]"
						 @click="direction(item.id)" :key="index">{{item.name}}</span>
					</div>
					<div class="card" v-for="(item,index) in infolist" :key='index' @click="toPositionDetails(item.id)">
						<div class="cardheader">
							<span class="title">{{item.name}}</span>
							<span class="time">发布时间：{{item.releaseTime}}</span>
							<span class="compensation" v-if="item.salary != 0">
								{{item.salary}}-{{item.salaryEnd}}K
							</span>
							<span class="compensation" v-else>
								面议
							</span>
						</div>
						<div class="synopsis">
							<span class="synopsissp">{{item.recordSchoolingName}}</span>
							<span class="synopsissp">{{item.workingPlace}}</span>
							<span class="synopsissp">{{item.jobCategoryName}}</span>
							<span>{{item.recruitNumber}}人</span>
						</div>
						<div class="info">
							<div v-html="item.jobDescription.replace(/\n/g,'<br/>')">
							</div>
						</div>
					</div>
					<Pagination :pagesize="pagesize" :total="total" @chang="handleSizeChange"></Pagination>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import Pagination from '../../../components/Pagination'
	import {
		HiringJobCategoryList,
		HiringFrontendQuery,
		HiringDirectionList
	} from '../../../api/index.js'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				buttontype: '',
				form: {
					value: '',
					type: '2',
					JobCategoryId: '',
				},
				HiringDirectionList: [],
				total: 100,
				pagesize: 5,
				PageNo: 1,
				infolist: [],
				data: [],
				defaultProps: {
					children: 'children',
					label: 'label'
				}
			}
		},
		mounted() {
			HiringJobCategoryList().then(res => {
				this.data = res.data
			})

			HiringDirectionList().then(res => {
				this.HiringDirectionList = res.data
			})
			this.getinfolist()
		},
		methods: {
			getinfolist() {
				HiringFrontendQuery({
					Name: this.form.value,
					HiringDirectionId: this.form.type,
					JobCategoryId: this.form.JobCategoryId,
					PageNo: this.PageNo,
					PageSize: this.pagesize
				}).then(res => {
					this.PageNo = 1
					this.infolist = res.data.rows
					this.total = res.data.totalRows
				})
			},
			resetChecked() {
				this.$refs.tree.setCheckedKeys([]);
			},
			getCheckedNodes() {
				let list = []
				this.$refs.tree.getCheckedNodes().forEach(function(item, index) {
					list.push(item.id)
				})
				this.form.JobCategoryId = list.join(',')
				this.getinfolist()
			},
			towelfare() {
				this.$router.push('welfare')
			},
			toprogress() {
				this.$router.push('progress')
			},
			getlist() {
				this.buttontype = 'primary'
			},
			direction(val) {
				this.form.type = val
				this.getinfolist()
			},
			handleSizeChange(val) {
				this.PageNo = val
				this.getinfolist()
			},
			toPositionDetails(id) {
				this.$router.push({
					path: 'PositionDetails',
					query: {
						id: id
					}
				})
			},
		}
	}
</script>

<style scoped lang="less">
	.Position {
		width: 80%;
		margin: 0 auto;

		.header {
			padding: 59px 0;
			font-size: 28px;
			border-bottom: 1px solid #979797;

			.title1 {
				font-family: SourceHanSansCN-Bold, SourceHanSansCN;
				font-weight: bold;
			}

			.arrows {
				color: #BEBEBE;
				padding-left: 25px;
			}

			.title2 {
				font-weight: 500;
				margin-left: 97px;
				color: #B4B4B4;
				cursor: pointer;
			}

			.title2hover {
				color: #2F7FFC;
				padding-bottom: 15px;
				border-bottom: 6px solid #2F7FFC;

			}

		}


		.conter {
			margin-top: 70px;
			display: flex;

			.timeline {
				width: 256px;
				margin-top: 33px;

				.custom-tree-node {
					font-size: 16px;
				}

				.operation {
					font-size: 14px;
					font-weight: bold;
					line-height: 21px;
					cursor: pointer;
					padding-bottom: 20px;
					border-bottom: 2px solid #979797;

					.close {
						float: right;
						color: #B4B4B4;
					}
				}

				.title {
					margin: 33px 0;
					font-size: 24px;
					font-weight: 500;
					line-height: 36px;
				}

			}

			.list {
				padding-left: 58px;
				border-left: 1px solid #979797;
				margin-left: 56px;

				.searchinput {
					width: 1032px;
					border-radius: 15px;
					border: 1px solid #E8E8E8;
					box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);
					margin-top: 17px;

					/deep/.el-input__inner {
						height: 64px;

					}

					/deep/.el-input-group__append {
						background-color: #2F7FFC;
						color: #fff;
						width: 64px;
						padding: 0;
						text-align: center;
						font-size: 22.63px;
						border-radius: 0 15px 15px 0;
						cursor: pointer;
					}
				}

				.direction {
					margin-top: 58px;

					span {
						font-size: 24px;
						font-weight: 500;
					}

					.directionbutton {
						font-size: 16px;
						margin-left: 24px;
						padding: 13px 17px;
						border-radius: 6px;
						border: 1px solid #2F7FFC;
						color: #2F7FFC;
						cursor: pointer;
					}

					.directionbuttonclick {
						color: #fff;
						background-color: #2F7FFC;
					}


				}

				.card:hover {
					box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.13);

				}

				.card {
					width: 932px;
					margin-top: 28px;
					padding: 43px 45px;
					border-radius: 17px;
					
					.cardheader {
						.title {
							font-size: 28px;
							font-weight: 500;
							color: #333333;
							line-height: 42px;
							vertical-align: middle;
						}

						.time {
							font-size: 14px;
							font-weight: 500;
							color: #A2A3A4;
							line-height: 21px;
							margin-left: 17px;
							vertical-align: middle;
						}

						.compensation {
							float: right;
							font-size: 28px;
							font-weight: 500;
							color: #FF825C;
							line-height: 42px;
							vertical-align: middle;
						}
					}

					.synopsis {
						margin-top: 33px;
						color: #2F7FFC;
						font-size: 24px;
						font-weight: 500;

						.synopsissp {
							padding-right: 12px;
							border-right: 3px solid #A2A3A4;
							margin-right: 12px;
						}
					}

					.info {
						margin-top: 30px;
						font-size: 18px;
						font-weight: 500;
						color: #A2A3A4;
						line-height: 36px;
						height: 62px;
						overflow:hidden;
					}
				}
			}
		}
	}
</style>
