<template>
	<div class="Pagination">
		   <el-pagination  :page-size="$props.pagesize" layout="prev,  pager, next" :total="$props.total" @current-change="handleIndexChange">
			</el-pagination>
	</div>
</template>

<script>
	export default {
		 props: {
		    pagesize: {
		      type: Number,
		      default: 1
		    },
			total: {
		      type: Number,
		      default: 1
		    },
		  },
		data() {
			return {

			}
		},
		mounted() {
		},
		methods: {
			handleIndexChange(val){
				 this.$emit('chang', val);
			}
		}
	}
</script>

<style scoped lang="less">
	.Pagination{
		margin-top: 140px;
		float: right;
		padding-bottom:50px ;
	}
	
	/deep/.el-pager li {
		font-size: 16px;
		line-height: 32px;
		vertical-align: bottom;
	}

	/deep/.el-icon-arrow-left {
		content: url(../assets/public/prev.png);
		width: 35px;
		height: 32px;
	}

	/deep/.el-icon-arrow-right {
		content: url(../assets/public/next.png);
		width: 35px;
		height: 32px;
	}
</style>
